import { Component, Vue } from "vue-property-decorator";
import UlContentHeader from "@/components/UlContentHeader.vue";
import UlBreadcrumbs from "@/components/UlBreadcrumbs.vue";
import UIDataTable from "@/components/UIDataTable.vue";
import { SessionStorage } from "@/store/session-storage";
import { DEFAULT_TABLE_OPTIONS, TableOptions } from "@/api/request";
import { SearchRequest as SettingSearchRequest } from "@/api/talk-setting-shop/request";
import { SearchRequest as TalkSearchRequest } from "@/api/talk-shop/request";
import { SearchItem as SettingItem } from "@/api/talk-setting-shop/response";
import { SearchItem as TalkItem } from "@/api/talk-shop/response";
import TalkSettingShopSearch from "@/store/talk-setting-shop/search";
import TalkSettingShopGet from "@/store/talk-setting-shop/get";
import TalkShopSearch from "@/store/talk-shop/search";
import TalkShopGet from "@/store/talk-shop/get";
import Flash, { ErrorAlert } from "@/store/common/flash";

/**
 * 表示中のページ
 */
enum Pages {
  Search,
  Talk
}

interface IOption {
  searchShopId: number | null;
  selectShopIds: number[] | null;
}

const MODULE_NAME = "communication/index";

@Component({ components: { UlContentHeader, UlBreadcrumbs, UIDataTable } })
export default class index extends Vue {
  // ------------
  // 固定値
  // ------------
  // タイトル
  headingMain = "コミュニケーション";
  headingSub = "Communication";

  menuProps = {
    closeOnClick: false,
    closeOnContentClick: false,
    disableKeys: true,
    openOnClick: false,
    maxHeight: 304
  };
  // ------------

  // ------------
  // 変動値
  // ------------
  // ローディングステータス
  isLoading = false;

  // 現在表示しているページ
  pages = Pages.Search;

  // 検索入力オプション
  searchInputOptions = {
    searchShopId: null as number | null,
    selectShopIds: null as number[] | null
  } as IOption;
  talkInputOptions = {
    searchShopId: null as number | null,
    selectShopIds: null as number[] | null
  } as IOption;

  // テーブル検索オプション（UIDataTableコンポーネントに渡す）
  searchTableOptions = DEFAULT_TABLE_OPTIONS;
  talkTableOptions = DEFAULT_TABLE_OPTIONS;

  // ボタンで切り替えた先の画面の初期化フラグ
  isRestored = false;

  // ------------

  /**
   * 現在表示しているページのパンくずリスト
   */
  get breadCrumbs() {
    // 20210120 トーク機能追加 cyber 李 start
    switch (this.pages) {
      case Pages.Search:
        return [
          { text: "コミュニケーション", disabled: true },
          { text: "店舗一覧", disabled: true }
        ];
      case Pages.Talk:
        return [
          { text: "コミュニケーション", disabled: true },
          { text: "トーク一覧", disabled: true }
        ];
      default:
        return [];
    }
    // 20210120 トーク機能追加 cyber 李 end
  }

  /**
   * 検索入力オプション
   */
  get inputOptions() {
    switch (this.pages) {
      case Pages.Search:
        return this.searchInputOptions;
      case Pages.Talk:
        return this.talkInputOptions;
      default:
        return {} as IOption;
    }
  }

  /**
   * 検索入力オプション
   */
  set inputOptions(options: IOption) {
    switch (this.pages) {
      case Pages.Search:
        this.searchInputOptions = options;
        break;
      case Pages.Talk:
        this.talkInputOptions = options;
        break;
    }
  }

  /**
   * テーブル検索オプション（UIDataTableコンポーネントに渡す）
   */
  get tableOptions() {
    switch (this.pages) {
      case Pages.Search:
        return this.searchTableOptions;
      case Pages.Talk:
        return this.talkTableOptions;
      default:
        return {} as TableOptions;
    }
  }

  /**
   * テーブル検索オプション（UIDataTableコンポーネントに渡す）
   */
  set tableOptions(options: TableOptions) {
    switch (this.pages) {
      case Pages.Search:
        this.searchTableOptions = options;
        break;
      case Pages.Talk:
        this.talkTableOptions = options;
        break;
    }
  }

  /**
   * 現在表示しているページのテーブルヘッダー
   */
  get tableHeaders() {
    switch (this.pages) {
      case Pages.Search:
        return [
          { text: "店舗ID", value: "id" },
          { text: "店舗名", value: "name" },
          {
            label: "修正",
            text: "",
            value: "edit",
            sortable: false
          }
        ];
      case Pages.Talk:
        return [
          { text: "店舗ID", value: "id" },
          { text: "店舗名", value: "name" },
          {
            label: "確認",
            text: "",
            value: "action",
            sortable: false
          },
          {
            label: "動画一覧",
            text: "",
            value: "movie",
            sortable: false
            }
        ];
      default:
        return [];
    }
  }

  /**
   * 画面を切り替えるボタンに表示する文言
   */
  get changeButtonTitle() {
    switch (this.pages) {
      case Pages.Search:
        return "トーク一覧";
      case Pages.Talk:
        return "戻る";
      default:
        return "";
    }
  }

  /**
   * テーブルに表示するアイテムリスト
   */
  get tableItems() {
    switch (this.pages) {
      case Pages.Search:
        return TalkSettingShopSearch.getItems;
      case Pages.Talk:
        return TalkShopSearch.getItems;
      default:
        return [];
    }
  }

  /**
   * 総件数
   */
  get totalCount() {
    switch (this.pages) {
      case Pages.Search:
        return TalkSettingShopSearch.getTotalCount;
      case Pages.Talk:
        return TalkShopSearch.getTotalCount;
      default:
        return 0;
    }
  }

  get shopItems() {
    switch (this.pages) {
      case Pages.Search:
        return TalkSettingShopGet.getItems;
      case Pages.Talk:
        return TalkShopGet.getItems;
      default:
        return [];
    }
  }

  /**
   * createdライフサイクルフック（UIDataTableコンポーネントに渡す）
   */
  async created() {
    this.pages = SessionStorage.getObject(MODULE_NAME, {
      page: Pages.Search
    }).page;
    switch (this.pages) {
      case Pages.Search:
        await this.restoreSearchParams();
        await this.searchTalkSettingShop();
        break;
      case Pages.Talk:
        await this.restoreTalkParams();
        await this.searchTalkShop();
        break;
    }
  }

  /**
   * beforeDestroyライフサイクルフック
   */
  async beforeDestroy() {
    await TalkSettingShopSearch.clearResponse();
    await TalkSettingShopGet.clearResponse();
    await TalkShopSearch.clearResponse();
    await TalkShopGet.clearResponse();
  }

  /**
   * ページング変更コールバック
   *
   * @param tableOptions TableOptions
   */
  async tableChangeCallback(tableOptions: TableOptions) {
    // 子コンポーネントへpagingOptionsの変更が通知される
    this.tableOptions = tableOptions;
    switch (this.pages) {
      case Pages.Search:
        await this.searchTalkSettingShop();
        break;
      case Pages.Talk:
        await this.searchTalkShop();
        break;
    }
  }

  /**
   * 検索ボタンがクリックされた際のコールバック
   */
  async searchClickCallback() {
    await Flash.clear();
    switch (this.pages) {
      case Pages.Search:
        await this.searchTalkSettingShop();
        break;
      case Pages.Talk:
        await this.searchTalkShop();
        break;
    }
  }

  /**
   * 画面の切り替えボタンが押下された際のコールバック
   */
  async changeClickCallback() {
    switch (this.pages) {
      case Pages.Talk:
        this.pages = Pages.Search;
        if (!this.isRestored) {
          this.restoreSearchParams();
          this.isRestored = true;
        }
        this.searchTalkSettingShop();
        break;
      case Pages.Search:
        this.pages = Pages.Talk;
        if (!this.isRestored) {
          this.restoreTalkParams();
          this.isRestored = true;
        }
        this.searchTalkShop();
        break;
    }
    SessionStorage.setObject(MODULE_NAME, { page: this.pages });
  }

  /**
   * テーブル行のアクションボタンがクリックされた際のコールバック
   *
   * @param item 選択行のAdminItem
   */
  async actionClickCallback(item: TalkItem) {
    const shop = this.shopItems.find(i => i.id == item.id);
    await this.$router.push({
      name: "communication-talk",
      params: { id: String(item.id) },
      query: { shop_name: shop ? shop.name : "" }
    });
  }

  /**
   * テーブル行の動画一覧ボタンがクリックされた際のコールバック
   *
   * @param item 選択行のAdminItem
   */
  async movieClickCallback(item: TalkItem) {
    const shop = this.shopItems.find(i => i.id == item.id);
    await this.$router.push({
    name: "communication-movie",
    params: { id: String(item.id) },
    query: { shop_name: shop ? shop.name : "" }
    });
    }

  /**
   * テーブル行の編集ボタンがクリックされた際のコールバック
   *
   * @param item 選択行のCommunicationItem
   */
  async editClickCallback(item: SettingItem) {
    await this.$router.push({
      name: "communication-edit",
      params: { id: String(item.id) }
    });
  }

  /**
   * テーブル行の削除ボタンがクリックされた際のコールバック
   *
   * @param item 選択行のCommunicationItem
   */
  deleteClickCallback(item: {}) {}

  /**
   * 店舗情報の一覧を取得する処理
   */
  async fetchTalkSettingShopList(): Promise<boolean> {
    if (TalkSettingShopGet.isSuccess) {
      return true;
    }

    await TalkSettingShopGet.get();
    if (!TalkSettingShopGet.isSuccess) {
      await Flash.setErrorNow({
        message: TalkSettingShopGet.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    return TalkSettingShopGet.isSuccess;
  }

  /**
   * 店舗情報の一覧を取得する処理
   */
  async fetchTalkShopList(): Promise<boolean> {
    if (TalkShopGet.isSuccess) {
      return true;
    }

    await TalkShopGet.get();
    if (!TalkShopGet.isSuccess) {
      await Flash.setErrorNow({
        message: TalkShopGet.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    return TalkShopGet.isSuccess;
  }

  /**
   * 検索処理
   */
  async searchTalkSettingShop() {
    this.isLoading = true;
    let isSuccess = await this.fetchTalkSettingShopList();
    if (!isSuccess) {
      this.isLoading = false;
      return;
    }

    let request = this.createRequest();
    await TalkSettingShopSearch.search(request);
    if (!TalkSettingShopSearch.isSuccess) {
      await Flash.setErrorNow({
        message: TalkSettingShopSearch.getMessage,
        showReloadButton: true
      } as ErrorAlert);
    }
    this.isLoading = false;
  }

  /**
   * 検索処理
   */
  async searchTalkShop() {
    this.isLoading = true;
    let isSuccess = await this.fetchTalkShopList();
    if (!isSuccess) {
      this.isLoading = false;
      return;
    }

    let request = this.createRequest();
    await TalkShopSearch.search(request);
    if (!TalkShopSearch.isSuccess) {
      await Flash.setErrorNow({
        message: TalkShopSearch.getMessage,
        showReloadButton: true
      } as ErrorAlert);
    }
    this.isLoading = false;
  }

  /**
   * 検索パラメータの復元
   */
  private async restoreSearchParams() {
    // トーク基本設定店舗
    await TalkSettingShopSearch.restore(
      this.inputOptions as SettingSearchRequest
    );
    const request = TalkSettingShopSearch.getSearchRequest;
    // 検索入力オプション
    request.searchShopId &&
      (this.inputOptions.searchShopId = request.searchShopId);
    request.selectShopIds &&
      (this.inputOptions.selectShopIds = request.selectShopIds);

    // 検索テーブルオプション
    request.page && (this.tableOptions = request);
  }

  /**
   * 検索パラメータの復元
   */
  private async restoreTalkParams() {
    // トーク機能有効店舗
    await TalkShopSearch.restore(this.inputOptions as TalkSearchRequest);
    const request = TalkShopSearch.getSearchRequest;
    // 検索入力オプション
    request.searchShopId &&
      (this.inputOptions.searchShopId = request.searchShopId);
    request.selectShopIds &&
      (this.inputOptions.selectShopIds = request.selectShopIds);

    // 検索テーブルオプション
    request.page && (this.tableOptions = request);
  }

  /**
   * dataから検索用のリクエストを作成する
   */
  private createRequest(): SettingSearchRequest | TalkSearchRequest {
    const request = this.tableOptions as SettingSearchRequest;
    if (this.inputOptions.searchShopId) {
      request.searchShopId = this.inputOptions.searchShopId;
    } else {
      delete request.searchShopId;
    }

    if (this.inputOptions.selectShopIds) {
      request.selectShopIds = this.inputOptions.selectShopIds;
    } else {
      delete request.selectShopIds;
    }

    if (this.pages === Pages.Talk) {
      return request as TalkSearchRequest;
    }

    return request;
  }
}
