import service from "@/api/service";
import { GetResponse } from "@/api/talk-setting-shop/response";
import { SearchRequest } from "@/api/talk-setting-shop/request";
import { SearchResponse } from "@/api/talk-setting-shop/response";

/**
 * トーク基本設定店舗情報一覧・検索APIをコールします。
 *
 * @param searchRequest トーク基本設定店舗検索のリクエストボディ
 * @return SearchResponse
 */
export async function search(searchRequest: SearchRequest) {
  const response = await service.post(
    "/search-talk-setting-shop",
    searchRequest
  );
  return response.data as SearchResponse;
}

/**
 * トーク基本設定店舗情報取得APIをコールします。
 *
 * @return GetResponse
 */
export async function get() {
  const response = await service.post("/get-talk-setting-shop");
  return response.data as GetResponse;
}
